import React, { useEffect, useState } from "react";

import "./index.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import storage from "../../../services/storage";

import { slide as MenuBurger } from "react-burger-menu";

import {
  FormControl,
  MenuItem,
  TextField,
  Modal,
  Box,
  InputLabel,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PlanoCadastro from "..";
import Mask from "../../../services/mask";
import ApiServices from "../../../services/apiServices";
import { toast } from "react-toastify";
import { useObjTelas } from "../../../context/ObjEnvio";
import Detalhe from "../../../assets/images/svg/detalhe.svg";
import Exluir from "../../../assets/images/svg/excluir.svg";
import moment from "moment";

export default function DadosTitular() {
  const navigate = useNavigate();
  const location = useLocation();
  const [ufs, setUfs] = useState([]);
  const [sexoGenero, setSexoGenero] = useState([{}]);
  const [cidades, setCidades] = useState([]);
  const { objTelas, setObjTelas } = useObjTelas({
    nome: "",
    sexo: {
      sexoId: "",
      nome: "",
    },
    estadoCivil: "3",
    cpf: "",
    email: "",
    telefone: "",
    dataNascimento: "",
    cep: "",
    endereco: "",
    numero: "",
    bairro: "",
    uf: {},
    cidade: {},
  });
  const [listaBeneficiario, setListaBeneficiario] = useState({
    nome: "",
    cpf: "",
    porcentagem: "",
  });
  const [listaBeneficiarioModal, setListaBeneficiarioModal] = useState({
    nome: "",
    cpf: "",
    porcentagem: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [validaIdade, setValidaIdade] = useState(true);
  const [openRegulamento, setOpenRegulamento] = useState(false);
  const [modalPorTras, setModalPorTras] = useState(false);
  const [concordo, setConcordo] = useState(null);
  const [listaEstadoCivil, setListaEstadoCivil] = useState([]);
  var InfoContrato = storage.get("INFOCONTRATO");

  function fnSexo() {
    ApiServices.apiGet("sexo").then((data) => {
      setSexoGenero(data.data);
    });
  }

  function fnListaCidades(u) {
    if (u == undefined) {
      setObjTelas((prevState) => ({
        ...prevState,
        titular: {
          ...prevState.titular,
          cep: "",
          endereco: "",
          numero: "",
          bairro: "",
          uf: {
            ufId: null,
          },
          cidade: {
            cidadeId: null,
            nome: null,
            ufId: null,
          },
        },
      }));
      setCidades([]);
      toast.error("CEP invalido");
    } else {
      ApiServices.apiGetEndereco(`cidade/` + u).then((data) =>
        setCidades(data)
      );
    }
  }

  function fnCepChange(event) {
    setObjTelas((prevState) => ({
      ...prevState,
      titular: {
        ...prevState.titular,
        cep: Mask.cep(event.target.value),
      },
    }));
  }

  function fnBlur() {
    const cep = objTelas.titular?.cep;
    if (Mask.cep(cep)?.length === 9) {
      fnCep();
    }
  }

  function fnCep() {
    const cep = objTelas.titular.cep;
    ApiServices.apiGetEndereco("cep/" + cep).then((data) => {
      setObjTelas((prevState) => ({
        ...prevState,
        titular: {
          ...prevState.titular,
          cep: data?.cep,
          endereco: data?.logradouro
            ? data.logradouro
            : objTelas.titular.endereco,
          uf: {
            ufId: data?.uf?.ufId,
          },
          cidade: {
            cidadeId: data?.cidade?.cidadeId,
            nome: data?.cidade?.nome,
            ufId: fnListaCidades(data?.cidade?.ufId),
          },
          bairro: data?.bairro,
        },
      }));
    });
  }

  async function fnEnvia() {
    if (!validarNome(objTelas.titular?.nome || "")) {
      toast.error("Campo Nome inválido");
      return false;
    }

    if (objTelas?.titular?.dataNascimento === "") {
      toast.error("Data de Nascimento não preenchida");
      return;
    }
    const idade = moment().diff(
      moment(objTelas?.titular?.dataNascimento),
      "years"
    );
    if (idade >= 18) {
      setValidaIdade(true);
    } else {
      setValidaIdade(false);
      toast.error("A data de nascimento deverá ser maior que 18 anos");
      return;
    }

    if (idade < 70) {
      setValidaIdade(true);
    } else {
      setValidaIdade(false);
      toast.error("A data de nascimento não poderá ser mais que 70 anos");
      return;
    }
    try {
      var objEnvio = {
        planoId: objTelas?.planoId,
        titular: {
          nome: objTelas?.titular?.nome,
          sexo: {
            sexoId: objTelas?.titular?.sexo?.sexoId,
          },
          email: objTelas?.titular?.email,
          cpf: Mask?.unMask(objTelas?.titular?.cpf),
          telefone: Mask?.unMask(objTelas?.titular?.telefone),
          dataNascimento:
            objTelas?.titular?.dataNascimento &&
            !isNaN(new Date(objTelas?.titular?.dataNascimento)) &&
            new Date(objTelas?.titular?.dataNascimento)
              .toISOString()
              .split("T")[0],
          cep: Mask?.unMask(objTelas?.titular?.cep),
          endereco: objTelas?.titular?.endereco,
          numero: objTelas?.titular?.numero,
          bairro: objTelas?.titular?.bairro,
          uf: {
            ufId: Number(objTelas?.titular?.uf?.ufId),
          },
          cidade: {
            cidadeId: Number(objTelas?.titular?.cidade?.cidadeId),
          },
          estadoCivil: { estadoCivilId: objTelas.titular?.estadoCivil },
        },
        // beneficiarios: objTelas?.beneficiarios?.map((beneficiario) => ({
        //   nome: beneficiario?.nome,
        //   cpf: Mask?.unMask(beneficiario?.cpf),
        //   porcentagem: beneficiario?.porcentagem,
        // })),
      };

      let response = await ApiServices.apiPost("contrato/titular", objEnvio);

      if (response.statusCode != 200) {
        toast.error(response.message);
      } else {
        storage.set("INFOCONTRATO", {
          ...storage?.get("INFOCONTRATO"),
          ...objEnvio,
        });
        navigate("/plano-cadastro/resumo");
      }
    } catch (e) {
      console.warn("err", e);
    }
  }

  function fnLimpaObjTelas() {
    setObjTelas({});
    navigate("/plano/detalhe");
  }

  function adicionarDependente() {
    if (
      listaBeneficiario.nome === "" ||
      listaBeneficiario.cpf === "" ||
      listaBeneficiario.porcentagem === ""
    ) {
      toast.error("Preencha todos os campos do dependente ");
    } else if (listaBeneficiario.cpf.length !== 14) {
      toast.error("CPF do beneficiário inválido.");
    } else {
      setObjTelas((prevState) => ({
        ...prevState,
        beneficiarios: [...prevState.beneficiarios, listaBeneficiario],
        numeroDependentes: prevState.numeroDependentes + 1,
      }));
      limparCampos();
    }
  }

  function excluirDependente(index) {
    setObjTelas((prevState) => {
      const dependenteAtual = [...prevState.beneficiarios];
      dependenteAtual.splice(index, 1); // remove o dependente do índice especificado
      return {
        ...prevState,
        beneficiarios: dependenteAtual,
        numeroDependentes: prevState.numeroDependentes - 1,
      };
    });
    // setNumeroDependentes((prevState) => prevState - 1);
  }

  function editarDependente(dependenteIndex) {
    const dependente = objTelas.beneficiarios[dependenteIndex];
    dependente.index = dependenteIndex;
    setListaBeneficiarioModal(dependente);
    setOpenModal(true);
  }

  function limparCampos() {
    setListaBeneficiario({
      nome: "",
      cpf: "",
      porcentagem: "",
    });
  }

  function salvarDependenteEditado() {
    if (
      listaBeneficiarioModal.nome === "" ||
      listaBeneficiarioModal.cpf === "" ||
      listaBeneficiarioModal.porcentagem === ""
    ) {
      toast.error("Preencha todos os campos do dependente ");
    } else if (listaBeneficiarioModal.cpf.length !== 14) {
      toast.error("CPF do beneficiário inválido.");
    } else {
      const dependentesAtualizados = objTelas.beneficiarios.map(
        (dependente, i) =>
          i === listaBeneficiarioModal.index
            ? listaBeneficiarioModal
            : dependente
      );
      setObjTelas((prevState) => ({
        ...prevState,
        beneficiarios: dependentesAtualizados,
      }));
      limparCampos();
      setOpenModal(false);
    }
  }

  useEffect(() => {
    const cep = objTelas.titular?.cep;
    if (Mask.cep(cep)?.length === 9) {
      fnCep();
    }
  }, [objTelas.titular?.cep]);

  useEffect(() => {
    fnSexo();
    ApiServices.apiGetEndereco(`uf`).then((data) => setUfs(data));
    if (InfoContrato && InfoContrato.titular) {
      const minhaData = InfoContrato.titular?.dataNascimento;
      const dataFormatada = minhaData ? minhaData.split("T")[0] : "";
      setObjTelas((prevState) => ({
        ...prevState,
        titular: {
          ...prevState.titular,
          cpf: Mask.cpf(InfoContrato.titular?.cpf),
          email: InfoContrato.titular?.email,
          telefone: Mask.cel(InfoContrato.titular?.telefone),
          nome: InfoContrato.titular?.nome,
          sexo: InfoContrato.titular?.sexo,
          dataNascimento: dataFormatada,
          cep: Mask.cep(InfoContrato.titular?.cep),
          endereco: InfoContrato.titular?.endereco,
          numero: InfoContrato.titular?.numero,
          bairro: InfoContrato.titular?.bairro,
          cidade: InfoContrato.titular?.cidade?.cidadeId,
          uf: InfoContrato.titular?.uf?.ufId,
          estadoCivil: InfoContrato.titular?.estadoCivil?.estadoCivilId,
        },
        beneficiarios: InfoContrato?.beneficiarios?.map((beneficiario) => ({
          nome: beneficiario.nome,
          cpf: Mask.cpf(beneficiario.cpf),
          porcentagem: beneficiario.porcentagem,
        })),
      }));
    }
  }, []);

  useEffect(() => {
    const cep = location?.state?.cep;
    if (cep) {
      setObjTelas((prevState) => ({
        ...prevState,
        titular: {
          ...prevState.titular,
          endereco: {
            ...prevState.titular?.endereco,
            cep: cep,
          },
        },
      }));
    }
  }, [location]);

  async function fnListarEstadoCivil() {
    try {
      let response = await ApiServices.apiGet(`estado-civil`);
      if (response.statusCode == 200) {
        setListaEstadoCivil(response.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
    }
  }
  function validarNome(nome) {
    // Verificar se o nome está completo
    if (nome.trim().split(" ").length < 2) {
      return false;
    }

    // Verificar se não há abreviações
    const palavras = nome.trim().split(" ");
    for (let palavra of palavras) {
      if (palavra.length < 2) {
        // Alteração aqui para permitir nomes com duas letras
        return false;
      }
    }

    // Verificar se não há caracteres especiais ou números
    const regex = /^[a-zA-Z\u00C0-\u017F\s']+$/;
    if (!regex.test(nome)) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    console.log("validaIdade", validaIdade);
  }, [validaIdade]);

  async function fnContratoMeu() {
    try {
      let response = await ApiServices.apiGet("contrato/meu");
      if (response.statusCode == 200) {
        setObjTelas((prevState) => ({
          ...prevState,
          planoId: response.data.plano.planoId,
          titular: {
            nome: response.data.titular.nome,
            sexo: {
              sexoId: response.data.titular.sexo.sexoId,
            },
            email: response.data.titular.email,
            cpf: Mask?.unMask(response.data.titular.cpf),
            telefone: Mask?.unMask(response.data.titular.telefone),
            dataNascimento:
              response.data.titular.dataNascimento &&
              !isNaN(new Date(response.data.titular.dataNascimento)) &&
              new Date(response.data.titular.dataNascimento)
                .toISOString()
                .split("T")[0],
            cep: Mask?.unMask(response.data.titular.cep),
            endereco: response.data.titular.endereco,
            numero: response.data.titular.numero,
            bairro: response.data.titular.bairro,
            uf: {
              ufId: Number(response.data.titular.uf?.ufId),
            },
            cidade: {
              cidadeId: Number(response.data.titular.cidade?.cidadeId),
            },
            estadoCivil: {
              estadoCivilId: response.data.titular.estadoCivil?.estadoCivilId,
            },
          },
        }));

        storage.set("INFOCONTRATO", response.data);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
    }
  }

  useEffect(() => {
    setTimeout(() => {
      fnContratoMeu();
    }, 1000);
    fnListarEstadoCivil();
  }, []);
  return (
    <>
      <div className="p-0">
        <div className="row mt-5">
          <h2 className="tituloDados">
            Confirme seus dados e atualize se necessário
          </h2>
        </div>
        <div className="row mt-5">
          <div className="col-md-8 mt-3">
            <TextField
              fullWidth
              className="form-control"
              label="Nome completo e sem abreviação*"
              value={objTelas.titular?.nome || ""}
              onChange={(e) => {
                setObjTelas((prevState) => ({
                  ...prevState,
                  titular: {
                    ...prevState.titular,
                    nome: e.target.value,
                  },
                }));
              }}
            />
          </div>
          <div className="col-md-4 mt-3">
            <TextField
              id="outlined-select-currency-native"
              select
              className="form-control"
              label="Sexo*"
              SelectProps={{
                native: true,
                value: objTelas.titular?.sexo?.sexoId || "",
                onChange: (e) => {
                  setObjTelas((prev) => ({
                    ...prev,
                    titular: {
                      ...prev.titular,
                      sexo: {
                        sexoId: e.target.value,
                      },
                    },
                  }));
                },
              }}
            >
              {!objTelas.titular?.sexo?.sexoId && <option value=""></option>}
              {sexoGenero.map((sexo, i) => (
                <option key={i} value={sexo.sexoId}>
                  {sexo.nome}
                </option>
              ))}
            </TextField>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-4 mt-3">
            <TextField
              fullWidth
              className="form-control"
              label="CPF*"
              value={objTelas.titular?.cpf || ""}
              disabled
              onChange={(e) => {
                setObjTelas((prevState) => ({
                  ...prevState,
                  titular: {
                    ...prevState.titular,
                    cpf: Mask.cpf(e.target.value),
                  },
                }));
              }}
            />
          </div>
          <div className="col-md-8 mt-3">
            <TextField
              fullWidth
              className="form-control"
              label="E-mail*"
              value={objTelas.titular?.email || ""}
              onChange={(e) => {
                setObjTelas((prevState) => ({
                  ...prevState,
                  titular: {
                    ...prevState.titular,
                    email: e.target.value,
                  },
                }));
              }}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-4 mt-3">
            <TextField
              fullWidth
              className="form-control"
              label="Celular*"
              value={objTelas.titular?.telefone || ""}
              onChange={(e) => {
                setObjTelas((prevState) => ({
                  ...prevState,
                  titular: {
                    ...prevState.titular,
                    telefone: Mask.cel(e.target.value),
                  },
                }));
              }}
            />
          </div>
          <div className="col-md-4 mt-3">
            <TextField
              fullWidth
              className="form-control"
              label="Data de nascimento*"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={objTelas.titular?.dataNascimento || ""}
              onChange={(e) => {
                setObjTelas((prevState) => ({
                  ...prevState,
                  titular: {
                    ...prevState.titular,
                    dataNascimento: e.target.value,
                  },
                }));
              }}
              inputProps={{
                max: new Date().toISOString().split("T")[0],
              }}
              error={!validaIdade && objTelas?.titular?.dataNascimento}
              helperText={
                !validaIdade && objTelas?.titular?.dataNascimento
                  ? "Data inválida"
                  : ""
              }
            />
          </div>
          <div className="col-md-4 mt-3">
            <TextField
              label="Estado civil*"
              select
              className="form-control"
              InputLabelProps={{ shrink: true }}
              value={objTelas.titular?.estadoCivil || ""}
              onChange={(e) => {
                setObjTelas((prevState) => ({
                  ...prevState,
                  titular: {
                    ...prevState.titular,
                    estadoCivil: e.target.value,
                  },
                }));
              }}
            >
              <MenuItem value={null}>Selecione</MenuItem>
              {(listaEstadoCivil || [])?.map((e, i) => (
                <MenuItem key={e.i} value={e.estadoCivilId}>
                  {e.nome}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-4 mt-3">
            <FormControl fullWidth>
              <TextField
                fullWidth
                className="form-control"
                label="CEP*"
                InputLabelProps={{ shrink: true }}
                value={Mask.cep(objTelas.titular?.cep) || ""}
                onChange={fnCepChange}
                onBlur={() => fnBlur()}
              />
              <span className="text-end mt-1 naoSeiCep">
                <a
                  href="https://buscacepinter.correios.com.br/app/endereco/index.php?t"
                  target="_blank"
                >
                  Não sei o CEP
                </a>
              </span>
            </FormControl>
          </div>
          <div className="col-md-4 mt-3">
            <TextField
              fullWidth
              className="form-control"
              label="Endereço*"
              InputLabelProps={{ shrink: true }}
              value={objTelas.titular?.endereco || ""}
              onChange={(e) => {
                setObjTelas((prevState) => ({
                  ...prevState,
                  titular: {
                    ...prevState.titular,
                    endereco: e.target.value,
                  },
                }));
              }}
            />
          </div>
          <div className="col-md-4 mt-3">
            <TextField
              fullWidth
              className="form-control"
              label="Número*"
              InputLabelProps={{ shrink: true }}
              value={objTelas.titular?.numero || ""}
              onChange={(e) => {
                setObjTelas((prevState) => ({
                  ...prevState,
                  titular: {
                    ...prevState.titular,
                    numero: e.target.value,
                  },
                }));
              }}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-4 mt-3">
            <TextField
              fullWidth
              className="form-control"
              label="Bairro*"
              InputLabelProps={{ shrink: true }}
              value={objTelas.titular?.bairro || ""}
              onChange={(e) => {
                setObjTelas((prevState) => ({
                  ...prevState,
                  titular: {
                    ...prevState.titular,
                    bairro: e.target.value,
                  },
                }));
              }}
            />
          </div>

          <div className="col-md-4 mt-3">
            <TextField
              select
              className="form-control"
              label="Estado*"
              value={
                ufs.find((uf) => uf.ufId === objTelas.titular?.uf?.ufId)
                  ?.ufId || ""
              }
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                const selectedUfId = e.target.value;
                setObjTelas((prevState) => ({
                  ...prevState,
                  titular: {
                    ...prevState.titular,
                    uf: {
                      ufId: selectedUfId,
                    },
                  },
                }));
                fnListaCidades(selectedUfId);
              }}
            >
              <MenuItem value="">Selecione uma opção</MenuItem>
              {ufs.map((uf) => (
                <MenuItem key={uf.ufId} value={uf.ufId}>
                  {uf.sigla}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="col-md-4 mt-3">
            <TextField
              label="Cidade*"
              select
              className="form-control"
              InputLabelProps={{ shrink: true }}
              value={
                cidades && cidades.length > 0
                  ? cidades.find(
                      (cidade) =>
                        cidade.cidadeId === objTelas.titular?.cidade?.cidadeId
                    )?.cidadeId || ""
                  : ""
              }
              onChange={(e) => {
                const selectedCidadeId = e.target.value;
                setObjTelas((prevState) => ({
                  ...prevState,
                  titular: {
                    ...prevState.titular,
                    cidade: {
                      cidadeId: selectedCidadeId,
                    },
                  },
                }));
              }}
            >
              <MenuItem value="">Selecione uma opção</MenuItem>
              {cidades &&
                cidades.length > 0 &&
                cidades.map((cidade) => (
                  <MenuItem key={cidade.cidadeId} value={cidade.cidadeId}>
                    {cidade.nome}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </div>
        {/* <div className="row mt-5">
          <h4>Incluir beneficiários</h4>
        </div>

        <div className="row mt-5">
          <div className="col-md-4 mt-3">
            <TextField
              fullWidth
              className="form-control"
              label="Nome"
              value={listaBeneficiario.nome}
              onChange={(e) => {
                setListaBeneficiario((prev) => ({
                  ...prev,
                  nome: e.target.value,
                }));
              }}
            />
          </div>

          <div className="col-md-4 mt-3">
            <TextField
              fullWidth
              className="form-control"
              label="CPF"
              value={listaBeneficiario.cpf || ""}
              onChange={(e) => {
                setListaBeneficiario((prev) => ({
                  ...prev,
                  cpf: Mask.cpf(e.target.value),
                }));
              }}
            />
          </div>
          <div className="col-md-4 mt-3">
            <TextField
              fullWidth
              className="form-control"
              label="Porcentagem %"
              value={listaBeneficiario.porcentagem || ""}
              onChange={(e) => {
                let inputValue = e.target.value;

                // Remove todos os caracteres não numéricos
                inputValue = inputValue.replace(/\D/g, "");

                // Converte para inteiro
                const numericValue = parseInt(inputValue, 10);

                // Garante que o valor não ultrapasse 100
                inputValue = Math.min(numericValue, 100);

                // Se o valor for NaN ou vazio, define como 0
                inputValue =
                  isNaN(numericValue) || inputValue === "" ? "" : inputValue;

                // Atualiza o estado
                setListaBeneficiario((prev) => ({
                  ...prev,
                  porcentagem: inputValue,
                }));
              }}
            />
          </div>
        </div>

        <div className="row mt-5 ">
          <div className="d-flex justify-content-center">
            <button
              className="botaoIncluir  mt-5"
              onClick={adicionarDependente}
            >
              INCLUIR BENEFICIÁRIO
            </button>
          </div>
        </div> */}

        <div className="row mt-5">
          <div className="col-12">
            <b className="bordaTexto btnCadastroTitular btnCadastroAtivado">
              Beneficiários
            </b>
            <br />
            <br />
            Os beneficiários do seguro são designados livremente pelo segurado
            titular, podendo ser modificados a qualquer momento. Na falta de
            beneficiário designado ou se, por qualquer motivo, não prevalecer a
            indicação feita, a indenização será paga de acordo com a legislação
            em vigor.
            <br />
            <br />
            Para fazer a designação de beneficiários basta entrar em contato com
            a Central de Atendimento MetLife, de segunda a sexta das 9h às 18h –
            exceto feriados, através dos números 3003 MetLife - 3003 5433
            (capitais e grandes centros) e 0800 MetLife - 0800 638 5433 (demais
            localidades).
          </div>
        </div>

        <div className="col-12 mt-4">
          <b className="bordaTexto btnCadastroTitular btnCadastroAtivado">
            Declaração pessoal de saúde:{" "}
            <u
              onClick={() => {
                setOpenRegulamento(true);
              }}
            >
              Clique aqui
            </u>
          </b>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-12 mt-5 ">
              <div>
                <label>
                  <input
                    type="checkbox"
                    className="me-2 form-check-input"
                    checked={concordo === true}
                    onChange={() => {
                      setConcordo(true);
                    }}
                  />
                  Concordo com todas as declarações
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    className="me-2 form-check-input"
                    checked={concordo === false}
                    onChange={() => {
                      setConcordo(false);
                    }}
                  />
                  Não concordo com todas as declarações
                </label>
              </div>
            </div>
          </div>
          {concordo === false ? (
            <div
              style={{
                color: "red",
                fontWeight: 700,
                marginTop: 15,
                minHeight: 80,
              }}
            >
              * Entendemos que você declara não estar em perfeitas condições de
              saúde no momento, então, infelizmente, não conseguiremos avançar
              com a sua compra no momento.
            </div>
          ) : (
            <div style={{ minHeight: 80 }}> </div>
          )}
        </div>
        {/* {Array.isArray(objTelas?.beneficiarios) &&
          objTelas?.beneficiarios.length > 0 && (
            <div className="row mt-5">
              <h4>Beneficiários incluídos</h4>
            </div>
          )}
        <div className="row mt-5">
          {Array.isArray(objTelas?.beneficiarios) &&
            objTelas?.beneficiarios.map((dependente, i) => (
              <div key={i}>
                <div className="row mt-5 linhaDependente">
                  <div className="col-md-4">
                    <span className="dependentesIncluidos">
                      NOME: <strong>{dependente.nome}</strong>
                    </span>
                  </div>
                  <div className="col-md-3">
                    <span className="dependentesIncluidos">
                      CPF:<strong>{dependente.cpf}</strong>
                    </span>
                  </div>
                  <div className="col-md-3">
                    <span className="dependentesIncluidos">
                      PORCENTAGEM: <strong>{dependente.porcentagem}%</strong>
                    </span>
                  </div>
                  <div className="col-md-1">
                    <img
                      className="pointer editarDependente"
                      onClick={() => editarDependente(i)}
                      src={Detalhe}
                      alt="iconeEditar"
                    />
                  </div>
                  <div className="col-md-1 mb-1">
                    <img
                      className="pointer excluirDependente"
                      onClick={() => excluirDependente(i)}
                      src={Exluir}
                      alt="iconeExcluir"
                    />
                  </div>
                </div>
                <hr />
              </div>
            ))}
        </div> */}
        {openModal && (
          <Modal keepMounted open={openModal}>
            <Box className="modalBeneficiario d-flex justify-content-center">
              <CloseIcon
                className="modalCloseIcon"
                onClick={() => {
                  setOpenModal(false);
                  limparCampos();
                }}
                style={{ fontSize: 30 }}
              />
              <div className="modalDependenteContainer mt-5">
                <div className="row text-center">
                  <h3>EDITAR BENEFICIÁRIO</h3>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <TextField
                      fullWidth
                      className="form-control"
                      label="Nome"
                      value={listaBeneficiarioModal.nome || ""}
                      onChange={(e) => {
                        setListaBeneficiarioModal((prev) => ({
                          ...prev,
                          nome: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <TextField
                      fullWidth
                      className="form-control"
                      label="CPF"
                      value={listaBeneficiarioModal.cpf || ""}
                      onChange={(e) => {
                        setListaBeneficiarioModal((prev) => ({
                          ...prev,
                          cpf: Mask.cpf(e.target.value),
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <TextField
                      fullWidth
                      className="form-control"
                      label="Porcentagem %"
                      value={listaBeneficiarioModal.porcentagem || ""}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Remove todos os caracteres não numéricos
                        inputValue = inputValue.replace(/\D/g, "");

                        // Converte para inteiro
                        const numericValue = parseInt(inputValue, 10);

                        // Garante que o valor não ultrapasse 100
                        inputValue = Math.min(numericValue, 100);

                        // Se o valor for NaN ou vazio, define como 0
                        inputValue =
                          isNaN(numericValue) || inputValue === ""
                            ? ""
                            : inputValue;

                        // Atualiza o estado
                        setListaBeneficiarioModal((prev) => ({
                          ...prev,
                          porcentagem: inputValue,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6 mt-2 mb-2 mt-5">
                    <button
                      className="btn btn-primary"
                      onClick={salvarDependenteEditado}
                      disabled={!listaBeneficiarioModal.nome}
                    >
                      EDITAR
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        )}
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-md-3">
            <button
              className="btn btn-outline-primary m-3"
              onClick={() => {
                fnLimpaObjTelas();
                window.scrollTo(0, 0);
              }}
            >
              VOLTAR
            </button>
          </div>
          <div className="col-md-3">
            <button
              disabled={!concordo}
              className="btn btn-primary m-3"
              onClick={() => {
                fnEnvia();
              }}
            >
              CONTINUAR
            </button>
          </div>
        </div>
        {openRegulamento && (
          <div className="modalExterno">
            <div className="modalInterno ">
              <CloseIcon
                className="modalCloseIcon"
                onClick={() => {
                  if (!modalPorTras) {
                    setOpenRegulamento(false);
                  } else {
                    setModalPorTras(false);
                    navigate("/login");
                    storage.del("PLANOID");
                  }
                }}
                style={{ fontSize: 30 }}
              />
              <div className="primeiroAcessoConteudo">
                <div className="row justify-content-center align-items-center">
                  {!modalPorTras ? (
                    <div className="col-md-12 ">
                      <h3 className="mb-3">Declaração Pessoal de Saúde</h3>
                      <div className="mt-3 texto-modal-regulamento">
                        (i) Declaro não fazer ou ter feito tratamento nos
                        últimos 60 meses para alguma doença no coração, câncer
                        ou hipertensão arterial (pressão alta);
                      </div>
                      <div className="mt-3 texto-modal-regulamento">
                        (ii) Declaro não ser portador de nenhuma doença no
                        coração, câncer ou hipertensão arterial (pressão alta);
                      </div>
                      <div className="mt-3 texto-modal-regulamento">
                        (iii) Declaro estar em perfeitas condições de saúde, não
                        possuir doenças preexistentes/degenerativas à
                        contratação deste seguro e não estar em nenhum
                        tratamento médico.
                      </div>
                    </div>
                  ) : (
                    <h5 className="mt-3 text-center texto-modal-regulamento">
                      Entendemos que você declara não estar em perfeitas
                      condições de saúde no momento, então, infelizmente, não
                      conseguiremos avançar com a sua compra no momento.
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import "./index.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useLoad } from "../../../context/Load";
import { useLocation, useNavigate } from "react-router";
import ApiServices from "../../../services/apiServices";
import Detalhe from "../../../assets/images/svg/detalhe.svg";
import Mask from "../../../services/mask";
import { toast } from "react-toastify";
import { useObjTelas } from "../../../context/ObjEnvio";
import moment from "moment/moment";
import storage from "../../../services/storage";

export default function PosResumo() {
  const { load, setLoad } = useLoad();
  const [infoCard, setInfoCard] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { objTelas, setObjTelas } = useObjTelas();

  async function fnCarregarInfoCard() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`contrato/meu`);
      storage.set("INFOCONTRATO", response.data);
      setInfoCard(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  const secoesAcordeao = [
    {
      id: "principais-coberturas",
      titulo: "Principais coberturas",
      conteudo:
        infoCard?.plano?.planoId === "b3a77680-1b21-4e02-a411-c1cd88b5d2a1"
          ? "Você e aqueles que mais ama estarão protegidos com R$ 150.000,00 em caso de morte, e R$ 150.000,00 em caso de invalidez permanente por acidente."
          : infoCard?.plano?.planoId === "2c23a01a-6288-493a-9d65-4b911b12ceee"
          ? "Você e aqueles que mais ama estarão protegidos com R$ 75.000 em caso de morte, R$ 75.000 em caso de invalidez permanente por acidente e 4 parcelas de R$ 700 em caso de Perda de renda."
          : "Você e aqueles que mais ama estarão protegidos com R$ 150.000 em caso de morte, R$ 150.000 em caso de invalidez permanente por acidente e 4 parcelas de R$ 1.000,00 em caso de perda de renda.",
    },
  ];

  function voltarListagemPlanos() {
    setObjTelas((prev) => ({ ...prev, checkTrocarPlano: true }));

    navigate("/");
  }

  async function fnEnvia() {
    try {
      var objEnvio = {
        bandeiraId: objTelas?.pagamento?.bandeiraId,
        numeroCartao: Mask.unMask(objTelas?.pagamento?.numeroCartao),
        nomeTitular: objTelas?.pagamento?.nomeTitular,
        validade: formatarData(objTelas?.pagamento?.validade),
      };

      let response = await ApiServices.apiPost(
        "contrato/pagamento/confirmar",
        objEnvio
      );

      if (response.statusCode == 200) {
        toast.success(response.data);
        window.scrollTo(0, 0);
        navigate("/login");
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      console.warn("err", e);
    }
  }

  function formatarData(data) {
    if (data) {
      const partesData = data.split("/");
      if (partesData.length === 2) {
        const mes = partesData[1];
        const ano = partesData[0].padStart(2, "0");
        const dia = "01";

        return `20${ano}-${mes}-${dia}`;
      }
    }
    return null;
  }

  const preco = infoCard?.periodo?.custo?.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  useEffect(() => {
    fnCarregarInfoCard();
  }, []);

  return (
    <div className="modalExterno">
      <div className="modalInterno  pe-0" style={{ maxWidth: "1000px" }}>
        <div
          style={{
            height: "70vh",

            overflowY: "auto",
            paddingRight: 30,
          }}
        >
          {/* Detalhes do seguro */}
          <div>
            Você contratou o seguro
            <br />
            <b>{infoCard?.plano?.nome}</b>
          </div>
          <br />
          Aqui estão as principais informações sobre seu seguro:
          <br />
          <div>{objTelas?.titular?.nome}</div>
          <br />
          Vigência do seguro <b>{infoCard?.periodo?.validade} meses</b>
          <br />
          <div>
            Início de vigência: <b>{moment().format("DD/MM/YYYY")}</b>
          </div>
          <br />
          Coberturas e Capitais:
          <br />
          <br />
          <div className="cadaCardDetCobfertura">
            <div>
              {infoCard?.plano?.planoId ===
              "b3a77680-1b21-4e02-a411-c1cd88b5d2a1" ? (
                <div>
                  Morte:<b> R$ 150.000,00</b>
                </div>
              ) : infoCard?.plano?.planoId ===
                "2c23a01a-6288-493a-9d65-4b911b12ceee" ? (
                <div>
                  Morte:<b> R$ 75.000</b>
                </div>
              ) : (
                <div>
                  Morte:<b> R$ 150.000,00</b>
                </div>
              )}
            </div>

            {infoCard?.plano?.planoId ===
              "b3a77680-1b21-4e02-a411-c1cd88b5d2a1" && (
              <div>
                <div>
                  Invalidez Permanente por Acidente: <b>R$ 150.000,00</b>
                </div>
              </div>
            )}
            {infoCard?.plano?.planoId ===
              "2c23a01a-6288-493a-9d65-4b911b12ceee" && (
              <div>
                <div>
                  Invalidez Permanente por Acidente:<b> R$ 75.000,00</b>
                </div>
              </div>
            )}
            {infoCard?.plano?.planoId ===
              "ff5d327d-30d7-45fe-8170-41c654bd11c1" && (
              <div>
                <div>
                  Invalidez Permanente por Acidente: <b>R$ 150.000,00</b>
                </div>
              </div>
            )}
            {infoCard?.plano?.planoId !==
            "b3a77680-1b21-4e02-a411-c1cd88b5d2a1" ? (
              <div>
                {infoCard?.plano?.planoId ===
                "ff5d327d-30d7-45fe-8170-41c654bd11c1" ? (
                  <div>
                    Perda de renda:<b> 4 parcelas de R$ 1.000,00</b>
                  </div>
                ) : (
                  infoCard?.plano?.planoId ===
                    "2c23a01a-6288-493a-9d65-4b911b12ceee" && (
                    <div>
                      Perda de renda:<b> 4 parcelas de R$ 700,00</b>
                    </div>
                  )
                )}
              </div>
            ) : (
              <div style={{ display: "none" }}></div>
            )}
          </div>
          <br />
          <hr />
          <br />
          <div>
            <div>
              Produto registrado na SUSEP sob nº 15414.600674/2022-38. <br />
            </div>
            Seguradora: Metropolitan Life Seguros e Previdência Privada S.A,
            código SUSEP 0635-1.
            <br />
            CNPJ da seguradora: 02.102.498/0001 29.
            <br />
            Você pode consultar os detalhes do seu seguro em www.metlife.com.br.
            <br />
            Para conhecer na íntegra as condições do produto, <br />
            consulte as Condições Gerais do Produto disponível no <br />
            site www.metlife.com.br. <br />
            A aceitação da proposta está sujeita à análise do risco. <br />
            O registro do produto é automático e não representa aprovação ou
            recomendação por parte da Susep.
            <br />
            O segurado poderá consultar a situação cadastral do corretor de
            seguros e da sociedade seguradora no sítio eletrônico
            www.susep.gov.br.
            <br />
            Se o Segurado, seu representante legal, ou seu corretor de seguros
            fizer declarações inexatas ou omitir circunstâncias que possam
            influir na aceitação da Proposta ou no valor do Prêmio, a Seguradora
            não pagará qualquer Indenização com base no presente seguro, além de
            estar o Segurado obrigado ao pagamento do prêmio vencido. <br />
            As condições contratuais deste produto encontram-se registradas na
            SUSEP e poderão ser consultadas no endereço eletrônico
            www.susep.gov.br, de acordo com o número de processo SUSEP.
            <br />
            Para outras informações DISQUE SUSEP 0800-021-8484 de 2ª a 6ª das
            9:30h as 17h SAC.
            <br />
            Este seguro é por prazo determinado tendo a seguradora a faculdade
            de não renovar a apólice na data de vencimento, sem devolução dos
            prêmios pagos nos termos da apólice, de acordo com o número de
            processo constante da apólice/proposta. <br />
            O segurado poderá desistir do seguro contratado no prazo de 7 (sete)
            dias corridos a contar da formalização da proposta e poderá exercer
            seu direito de arrependimento pelo mesmo meio utilizado para
            contratação, sem prejuízo de utilizar outros meios disponibilizados
            pela Seguradora. As Condições Gerais completas estão disponíveis
            para consulta no site www.metlife.com.br.
            <br />
            Central de Atendimento: 3003 Life - 3003 5433 (capitais e grandes
            centros), 0800 MetLife - 0800 638 5433 (demais localidades). <br />
            SAC - Serviço de Apoio ao Cliente Reclamações, Cancelamentos,
            Sugestões e Elogios: 0800 746 3420 (24 horas por dia, 7 dias por
            semana, em todo o Brasil). <br />
            Atendimento ao deficiente auditivo ou de fala: 0800 723 0658 (24
            horas por dia, 7 dias por semana, em todo o Brasil). <br />
            Ouvidoria 0800 202 1264 (24 horas por dia, 7 dias por semana, em
            todo o Brasil).
            <br />
          </div>
          <div className="row justify-content-center">
            <div className="col-6 m-4">
              <button
                className="btn btn-outline-primary"
                onClick={() => {
                  navigate("/login");
                }}
              >
                FECHAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

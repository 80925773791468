import React, { useEffect, useState } from "react";
import "./index.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper";
import { useLoad } from "../../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../../services/apiServices";
import PlanoCadastro from "..";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import Cartao from "../../../assets/images/png/cartao.png";
import Cartoes from "../../../assets/images/png/cartoes.png";
import Mask from "../../../services/mask";
import { toast } from "react-toastify";
import { useObjTelas } from "../../../context/ObjEnvio";
import storage from "../../../services/storage";
import condicoes from "../../../assets/pdf/Condições Gerais.pdf";
import manual from "../../../assets/pdf/Manual do Segurado.pdf";
import PosResumo from "../posResumo";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
export default function Pagamento() {
  const { load, setLoad } = useLoad();
  const [infoCard, setInfoCard] = useState([]);
  const navigate = useNavigate();
  const { objTelas, setObjTelas } = useObjTelas();
  const [listaBandeiraCard, setListaBandeiraCard] = useState([]);
  const [selecionaBandeira, setSelecionaBandeira] = useState("");
  const [mostraModal, setMostraModal] = useState(false);

  const planoid = storage.get("PLANOID");

  async function fnEnvia() {
    try {
      var objEnvio = {
        bandeiraId: selecionaBandeira,
        numeroCartao: Mask.unMask(objTelas.pagamento.numeroCartao),
        nomeTitular: objTelas.pagamento.nomeTitular,
        validade: formatarData(objTelas.pagamento.validade),
      };

      let response = await ApiServices.apiPost(
        "contrato/pagamento/validar",
        objEnvio
      );

      if (response.statusCode !== 200) {
        toast.error(response.message);
      } else {
        window.scrollTo(0, 0);
        fnCarregarCampanhas();
      }
    } catch (e) {
      console.warn("err", e);
    }
  }

  async function fnCarregarCampanhas() {
    try {
      var objEnvio = {
        bandeiraId: objTelas?.pagamento?.bandeiraId,
        numeroCartao: Mask.unMask(objTelas?.pagamento?.numeroCartao),
        nomeTitular: objTelas?.pagamento?.nomeTitular,
        validade: formatarData(objTelas?.pagamento?.validade),
      };

      let response = await ApiServices.apiPost(
        "contrato/pagamento/confirmar",
        objEnvio
      );
      storage.del("PERIODOID");
      if (response.statusCode == 200) {
        toast.success(
          "Parabéns! Contratação finalizada com sucesso. O resumo de sua compra será enviado para o seu e-mail com as principais informações sobre o seu seguro, onde acessar as condições gerais, o manual do segurado e a apólice individual."
        );
        window.scrollTo(0, 0);
        setMostraModal(true);
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      console.warn("err", e);
    }
  }

  function formatarData(data) {
    if (data) {
      const partesData = data?.split("/");
      if (partesData?.length === 2) {
        const mes = partesData[0]?.padStart(2, "0");
        const ano = partesData[1];
        const dia = "01";

        return `20${ano}-${mes}-${dia}`;
      }
    }
    return null;
  }

  const handleCheckChange = (e) => {
    setObjTelas((prevState) => ({
      ...prevState,
      checkTermos: e.target.checked,
    }));
  };

  function fnLimpaObjTelas() {
    setObjTelas({});
    navigate("/plano-cadastro/dados-titular");
  }

  async function fnCarregarContrato() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`contrato/meu`);

      if (response.statusCode == 200) {
        storage.set("INFOCONTRATO", response.data);
        setInfoCard(response.data);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  const preco = infoCard?.periodo?.custo.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  async function fnListaBandeira() {
    try {
      const response = await ApiServices.apiGet("bandeira");
      if (response?.statusCode === 200) {
        setListaBandeiraCard(response?.data);
      }
    } catch (error) {
      console.error("Error fetching card brands:", error);
    }
  }

  const formatarValidade = (input) => {
    const digitos = input?.replace(/\D/g, "")?.substring(0, 4);

    if (digitos?.length >= 3) {
      return `${digitos?.slice(0, 2)}/${digitos?.slice(2)}`;
    }

    return digitos;
  };

  useEffect(() => {
    fnListaBandeira();
    fnCarregarContrato();
  }, []);

  const [state, setState] = useState({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    focus: "",
  });

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputFocus = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  };
  return (
    <>
      <div className="p-0">
        <div className="row d-flex justify-content-center flex-column-reverse flex-lg-row mt-4">
          <div className="col-lg-8 d-flex align-items-lg-start align-items-center flex-column p-0">
            <div className="container p-0">
              <div className="row">
                {/* <div className="container"></div> */}
                <div className="col-md-6 mt-5 d-flex align-items-center">
                  <FormControl component="fieldset">
                    <RadioGroup name="controlled-radio-buttons-group">
                      <FormControlLabel
                        value="1"
                        control={<Radio checked={true} />}
                        label={
                          <div className="d-flex align-items-center">
                            <img src={Cartao} alt="cartao" />
                            <span className="radioPagamento ms-3">
                              Cartão de crédito/débito
                            </span>
                          </div>
                        }
                        className="align-items-center"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="col-md-6  d-flex align-items-center  mt-5">
                  <img
                    src={Cartoes}
                    alt="cartoes"
                    style={{ width: "100%", maxWidth: "389px" }}
                  />
                </div>
              </div>
              <div className="container">
                <hr />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      className=" w-100"
                      value={selecionaBandeira}
                      onChange={(e) => {
                        setSelecionaBandeira(e.target.value);
                        setObjTelas((prevState) => ({
                          ...prevState,
                          pagamento: {
                            ...prevState.pagamento,
                            bandeiraId: e.target.value,
                          },
                        }));
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Bandeira" }}
                    >
                      <MenuItem value="" disabled>
                        Selecione a Bandeira
                      </MenuItem>
                      {listaBandeiraCard.map((bandeira) => (
                        <MenuItem
                          key={bandeira.codigo}
                          value={bandeira.bandeiraId}
                        >
                          {bandeira.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mt-4">
                  <Cards
                    style={{ width: "100%" }}
                    number={objTelas.pagamento?.numeroCartao}
                    expiry={formatarValidade(objTelas.pagamento?.validade)}
                    cvc={123}
                    name={objTelas.pagamento?.nomeTitular}
                    placeholders={{ name: "NOME DO TITULAR" }}
                    locale={{ valid: "VALIDADE" }}
                    acceptedCards={[
                      "visa",
                      "mastercard",
                      "elo",
                      "american-express",
                      "discover",
                      "hipercard",
                    ]}
                    // focused={state.focus}
                  />
                </div>

                <div className="col-md-6 mt-4">
                  <TextField
                    fullWidth
                    className="form-control"
                    label="Número do cartão*"
                    value={objTelas.pagamento?.numeroCartao}
                    onChange={(e) => {
                      setObjTelas((prevState) => ({
                        ...prevState,
                        pagamento: {
                          ...prevState.pagamento,
                          numeroCartao: Mask.creditCard(e.target.value),
                        },
                      }));
                    }}
                  />

                  <TextField
                    fullWidth
                    className="form-control my-3"
                    label="Nome do titular do cartão*"
                    value={objTelas.pagamento?.nomeTitular}
                    onChange={(e) => {
                      setObjTelas((prevState) => ({
                        ...prevState,
                        pagamento: {
                          ...prevState.pagamento,
                          nomeTitular: e.target.value,
                        },
                      }));
                    }}
                  />

                  <TextField
                    fullWidth
                    className="form-control"
                    label="Validade do Cartão*"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="MM/AA"
                    value={formatarValidade(objTelas.pagamento?.validade)}
                    onChange={(e) => {
                      setObjTelas((prevState) => ({
                        ...prevState,
                        pagamento: {
                          ...prevState.pagamento,
                          validade: e.target.value,
                        },
                      }));
                    }}
                    inputProps={{
                      maxLength: 5,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 d-flex justify-content-lg-end justify-content-center mt-5">
            <div className="cardPagamento">
              <div className="mt-2">
                <div>
                  <div className="subtotalValor">{infoCard?.plano?.nome}</div>
                  <div className="d-flex align-items-center">
                    <span>Vigência de: </span>
                    <span className="subtotalValor fs-5">
                      {infoCard?.periodo?.validade} meses
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span>Total mensal de: </span>
                    <span className="totalValor fs-5">{preco}</span>
                  </div>
                </div>
                <hr className="mt-4 mb-4" />
              </div>
              <div className="container d-flex mb-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={objTelas.checkTermos}
                    onChange={handleCheckChange}
                    id="declaracaoCheck"
                  />
                </div>
                <div className="form-check-label">
                  Declaro que li as
                  <u>
                    <a href={condicoes} target="_blank">
                       Condições Contratuais 
                    </a>
                  </u>
                  e o 
                  <u>
                    <a href={manual} target="_blank">
                      Manual do Segurado
                    </a>
                  </u>{" "}
                  e estou de acordo com as coberturas e condições apresentadas.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 justify-content-center">
        <div className=" col-lg-3 ">
          <button
            className="btn btn-outline-primary my-3 col-3"
            onClick={() => {
              fnLimpaObjTelas();
              window.scrollTo(0, 0);
            }}
          >
            VOLTAR
          </button>
        </div>
        <div className=" col-lg-3 ">
          <button
            className="btn btn-primary my-3"
            disabled={!objTelas.checkTermos}
            onClick={() => {
              fnEnvia();
            }}
          >
            CONCLUIR CONTRATAÇÃO
          </button>
        </div>
      </div>
      <div className="row p-5">
        <div className="col-12 text-center">
          A responsabilidade pela captura, coleta, armazenamento, utilização ou
          quaisquer outras formas de tratamento de todos os dados relativos ao
          pagamento é exclusiva da MetLife. O Banco GM e GM Corretora de seguros
          não possuem nenhum acesso a tais dados.
        </div>
      </div>

      {mostraModal && (
        <div className="modalExterno">
          <div className="modalInterno">
            <PosResumo />
          </div>
        </div>
      )}
    </>
  );
}
